import React from 'react';

export const Logo = (width,height ) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 224 23" fill="none">
  <path d="M76.6097 22.3578C74.9152 22.3578 73.2913 22.1359 71.738 21.6921C70.1846 21.2281 68.9339 20.633 67.9858 19.9068L69.6501 16.2152C70.5578 16.8607 71.627 17.3953 72.8576 17.8189C74.1083 18.2224 75.3691 18.4241 76.64 18.4241C77.6083 18.4241 78.385 18.3333 78.97 18.1518C79.5752 17.95 80.019 17.6777 80.3014 17.3348C80.5838 16.9918 80.725 16.5984 80.725 16.1546C80.725 15.5898 80.5031 15.146 80.0593 14.8232C79.6155 14.4803 79.0305 14.2079 78.3043 14.0062C77.5781 13.7843 76.7711 13.5826 75.8835 13.401C75.0161 13.1993 74.1386 12.9572 73.2509 12.6748C72.3835 12.3924 71.5867 12.0293 70.8605 11.5855C70.1342 11.1416 69.5391 10.5566 69.0751 9.8304C68.6313 9.10417 68.4094 8.17621 68.4094 7.04652C68.4094 5.83614 68.7322 4.73671 69.3777 3.74824C70.0434 2.73959 71.0319 1.94275 72.3432 1.35774C73.6746 0.752546 75.3388 0.449951 77.336 0.449951C78.6674 0.449951 79.9786 0.611335 81.2697 0.934103C82.5608 1.2367 83.7006 1.70068 84.689 2.32604L83.1761 6.04796C82.1876 5.48312 81.1991 5.06957 80.2106 4.80732C79.2222 4.5249 78.2539 4.38369 77.3057 4.38369C76.3576 4.38369 75.5809 4.49464 74.9757 4.71654C74.3705 4.93845 73.9368 5.23095 73.6746 5.59407C73.4123 5.93701 73.2812 6.34047 73.2812 6.80445C73.2812 7.34912 73.5031 7.79293 73.9469 8.13587C74.3907 8.45863 74.9757 8.72088 75.702 8.92261C76.4282 9.12434 77.225 9.32607 78.0925 9.5278C78.9801 9.72953 79.8576 9.96152 80.725 10.2238C81.6127 10.486 82.4196 10.839 83.1458 11.2829C83.872 11.7267 84.457 12.3117 84.9009 13.0379C85.3648 13.7641 85.5968 14.682 85.5968 15.7915C85.5968 16.9817 85.264 18.0711 84.5983 19.0595C83.9326 20.048 82.934 20.8449 81.6026 21.4501C80.2913 22.0552 78.6271 22.3578 76.6097 22.3578Z" fill="#0068FF"/>
  <path d="M0 0.813064V4.80732H8.8963C10.4093 4.80732 11.539 5.13009 12.2854 5.77563C13.0318 6.42116 13.405 7.32895 13.405 8.49898C13.405 9.64884 13.0318 10.5465 12.2854 11.1921C11.539 11.8376 10.4093 12.1604 8.8963 12.1604H0V21.9947H4.90204V16.1546H9.16863C11.0649 16.1546 12.6989 15.852 14.0707 15.2469C15.4424 14.6215 16.5015 13.7339 17.2479 12.584C17.9943 11.4342 18.3675 10.0725 18.3675 8.49898C18.3675 6.90531 17.9943 5.53355 17.2479 4.38369C16.5015 3.23383 15.4424 2.3563 14.0707 1.75111C12.6989 1.12575 11.0649 0.813064 9.16863 0.813064H0Z" fill="#0068FF"/>
  <path d="M21.8672 12.1906V21.9947H26.7693V16.0941H31.0359C31.1131 16.0941 31.1899 16.0936 31.2663 16.0926L35.3327 21.9947H40.6281L35.8964 15.2045L35.9379 15.1863C37.3097 14.5811 38.3687 13.7137 39.1151 12.584C39.8615 11.4342 40.2347 10.0725 40.2347 8.49898C40.2347 6.90531 39.8615 5.53355 39.1151 4.38369C38.3687 3.23383 37.3097 2.3563 35.9379 1.75111C34.5661 1.12575 32.9321 0.813064 31.0359 0.813064H21.8672V4.80732H30.7635C32.2765 4.80732 33.4062 5.13009 34.1526 5.77563C34.899 6.42116 35.2722 7.32895 35.2722 8.49898C35.2722 9.64884 34.899 10.5566 34.1526 11.2223C33.4062 11.8679 32.2765 12.1906 30.7635 12.1906H21.8672Z" fill="#0068FF"/>
  <path d="M95.9061 22.3578C94.2116 22.3578 92.5876 22.1359 91.0343 21.6921C89.481 21.2281 88.2303 20.633 87.2821 19.9068L88.9464 16.2152C89.8542 16.8607 90.9234 17.3953 92.1539 17.8189C93.4046 18.2224 94.6655 18.4241 95.9364 18.4241C96.9047 18.4241 97.6813 18.3333 98.2663 18.1518C98.8715 17.95 99.3153 17.6777 99.5978 17.3348C99.8802 16.9918 100.021 16.5984 100.021 16.1546C100.021 15.5898 99.7995 15.146 99.3557 14.8232C98.9119 14.4803 98.3269 14.2079 97.6006 14.0062C96.8744 13.7843 96.0675 13.5826 95.1799 13.401C94.3124 13.1993 93.4349 12.9572 92.5473 12.6748C91.6799 12.3924 90.883 12.0293 90.1568 11.5855C89.4306 11.1416 88.8355 10.5566 88.3715 9.8304C87.9277 9.10417 87.7058 8.17621 87.7058 7.04652C87.7058 5.83614 88.0285 4.73671 88.6741 3.74824C89.3398 2.73959 90.3283 1.94275 91.6395 1.35774C92.9709 0.752546 94.6352 0.449951 96.6323 0.449951C97.9637 0.449951 99.275 0.611335 100.566 0.934103C101.857 1.2367 102.997 1.70068 103.985 2.32604L102.472 6.04796C101.484 5.48312 100.495 5.06957 99.507 4.80732C98.5185 4.5249 97.5502 4.38369 96.6021 4.38369C95.6539 4.38369 94.8773 4.49464 94.2721 4.71654C93.6669 4.93845 93.2332 5.23095 92.9709 5.59407C92.7087 5.93701 92.5776 6.34047 92.5776 6.80445C92.5776 7.34912 92.7995 7.79293 93.2433 8.13587C93.6871 8.45863 94.2721 8.72088 94.9983 8.92261C95.7245 9.12434 96.5214 9.32607 97.3888 9.5278C98.2764 9.72953 99.154 9.96152 100.021 10.2238C100.909 10.486 101.716 10.839 102.442 11.2829C103.168 11.7267 103.753 12.3117 104.197 13.0379C104.661 13.7641 104.893 14.682 104.893 15.7915C104.893 16.9817 104.56 18.0711 103.895 19.0595C103.229 20.048 102.23 20.8449 100.899 21.4501C99.5877 22.0552 97.9234 22.3578 95.9061 22.3578Z" fill="#0068FF"/>
  <path d="M108.243 0.813064H113.145V21.9947H108.243V0.813064Z" fill="#0068FF"/>
  <path d="M118.172 0.813064H122.226L130.16 13.9655L137.961 0.813064H141.986L142.046 21.9947H137.447L137.42 9.28142L131.183 19.7555H128.974L122.771 9.55528V21.9947H118.172V0.813064Z" fill="#0068FF"/>
  <path d="M153.729 0.813064H158.57L168.042 21.9947H162.898L156.118 5.62734L149.311 21.9947H144.288L153.729 0.813064Z" fill="#0068FF"/>
  <path d="M54.2489 7.00021C53.9207 6.67196 53.3885 6.67196 53.0602 7.00021C52.732 7.32846 52.732 7.86066 53.0602 8.18891L55.6879 10.8166L49.592 10.8166C49.1278 10.8166 48.7515 11.193 48.7515 11.6572C48.7515 12.1214 49.1278 12.4977 49.592 12.4977L55.6879 12.4977L53.0602 15.1254C52.732 15.4537 52.732 15.9859 53.0602 16.3142C53.3885 16.6424 53.9207 16.6424 54.2489 16.3142L58.3115 12.2515C58.6398 11.9233 58.6398 11.3911 58.3115 11.0628L54.2489 7.00021Z" fill="#0068FF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M53.829 1.29049C48.0073 1.29049 43.2879 6.0099 43.2879 11.8316C43.2879 17.6533 48.0073 22.3727 53.829 22.3727C59.6507 22.3727 64.3701 17.6533 64.3701 11.8316C64.3701 6.0099 59.6507 1.29049 53.829 1.29049ZM44.969 11.8316C44.969 6.93833 48.9358 2.97158 53.829 2.97158C58.7222 2.97158 62.689 6.93833 62.689 11.8316C62.689 16.7248 58.7222 20.6916 53.829 20.6916C48.9358 20.6916 44.969 16.7248 44.969 11.8316Z" fill="#0068FF"/>
  <path d="M201.483 0.820597H206.324L215.795 22.0023H210.651L203.871 5.63487L197.065 22.0023H192.042L201.483 0.820597Z" fill="#0068FF"/>
  <path d="M218.337 0.820353L223.239 0.820719V22.0023H218.337V0.820353Z" fill="#0068FF"/>
</svg>

  );
}